import { ApolloProvider } from '@apollo/client';
// import { datadogRum } from '@datadog/browser-rum';
import { NextUIProvider } from '@nextui-org/react';
import { AnimatePresence } from 'framer-motion';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import lazyWithPreload from 'react-lazy-with-preload';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Logo, LogoContainer, Root } from './App.styles';
import AuthenticatedRoute from './components/routing/AuthenticatedRoute';
import UnauthenticatedRoute from './components/routing/UnauthenticatedRoute';
import { homePath } from './constants';
import { AnimatedNavigationProvider } from './contexts/AnimatedNavigationContext';
import { useAuthContext } from './contexts/AuthContext';
import { KPIsMetricsProvider } from './contexts/KpiMetricsContext';
import { KPIsAndFilterProvider } from './contexts/KPIsAndFilterContext';
import { OrganizationProvider } from './contexts/OrganizationContext';
import { SegmentsProvider } from './contexts/SegmentsContext';
import { SessionProvider } from './contexts/SessionContext';
import { refreshToken } from './graphql';
import { AppContext } from './hooks/AppContext';
import { GlobalStyles } from './theme/GlobalStyles';
import { darkTheme, lightTheme, nextUIThemeMap } from './theme/Themes';
import { configureAmplify } from './utils/Amplify';
import { errorToast, successToast } from './utils/CustomToasts/CustomToasts';
import { configureFirebase } from './utils/Firebase';
import NotFoundPage from './views/notFound/NotFoundPage';

configureAmplify();
configureFirebase();

const LoginModule = lazyWithPreload(() => import('./views/auth/LoginPage'));
const SignupModule = lazyWithPreload(() => import('./views/auth/SignupPage'));
const SignupConfirmModule = lazyWithPreload(() => import('./views/auth/SignupConfirmPage'));
const VerifyModule = lazyWithPreload(() => import('./views/auth/VerifyPage'));
const RecoveryMailModule = lazyWithPreload(() => import('./views/auth/RecoveryMailPage'));
const ResetPasswordModule = lazyWithPreload(() => import('./views/auth/ResetPasswordPage'));
const AppContainerModule = lazy(() => import('./views/AppContainer'));

// TODO: Replce datadog with something else. Maybe Firebase Analytics?
/*datadogRum.init({
  applicationId: 'fd30a4cf-817c-4298-9399-2165ffe2c3ab',
  clientToken: 'pub45a4a0f1f89ce3c29c43e200589ffc01',
  site: 'datadoghq.com',
  service: 'rlv-ui',
  env: process.env.REACT_APP_BUILD_STAGE,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();*/

function App(): any {
  const [themeName, setThemeName] = useState<'light' | 'dark'>('light');
  const [user, setUser] = useState<any | undefined>(undefined);

  const theme = themeName === 'dark' ? darkTheme : lightTheme;

  const showAlert = (alert: string) => {
    successToast(alert);
  };

  const showError = (error: string) => {
    errorToast(error);
  };

  const { loading, isAuthenticated, client } = useAuthContext();

  const preloadUnauthModules = () => {
    void LoginModule.preload();
    void SignupModule.preload();
    void SignupConfirmModule.preload();
    void VerifyModule.preload();
    void RecoveryMailModule.preload();
    void ResetPasswordModule.preload();
  };

  useEffect(() => {
    void refreshToken();
  }, []);

  useEffect(() => {
    if (loading) {
      // Trigger preloadUnauthModules during the initial rendering of the App component
      preloadUnauthModules();
    }
  }, [loading]);

  const Main = (): any => {
    return isAuthenticated ? <Navigate replace to={homePath} /> : <Navigate replace to="/login" />;
  };

  const AnimatedRoutes = () => {
    const location = useLocation();

    const contextValues = {
      showError,
      showAlert,
      user,
      setUser,
      themeName,
      setThemeName,
      location,
    };

    return (
      <AppContext.Provider value={contextValues}>
        <OrganizationProvider>
          <AnimatedNavigationProvider>
            <KPIsAndFilterProvider>
              <KPIsMetricsProvider>
                <SegmentsProvider>
                  <AnimatePresence initial={false} exitBeforeEnter={true}>
                    <Routes location={location} key={location.pathname}>
                      <Route path="/" element={<Main />} />
                      <Route element={<UnauthenticatedRoute />}>
                        <Route path="/login" element={<LoginModule />} />
                        <Route path="/signup" element={<SignupModule />} />
                        <Route path="/signup-confirm" element={<SignupConfirmModule />} />
                        <Route path="/verify" element={<VerifyModule />} />
                        <Route path="/recovery-mail" element={<RecoveryMailModule />} />
                        <Route path="/reset-password" element={<ResetPasswordModule />} />
                        <Route path="/*" element={<NotFoundPage isUnAuthenticated />} />
                      </Route>
                      <Route element={<AuthenticatedRoute />}>
                        <Route path="/app/*" element={<AppContainerModule />} />
                      </Route>
                    </Routes>
                  </AnimatePresence>
                </SegmentsProvider>
              </KPIsMetricsProvider>
            </KPIsAndFilterProvider>
          </AnimatedNavigationProvider>
        </OrganizationProvider>
      </AppContext.Provider>
    );
  };

  const animation = (
    <LogoContainer>
      <Logo />
    </LogoContainer>
  );

  return (
    <>
      {client && (
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <NextUIProvider theme={nextUIThemeMap(theme)}>
              <>
                <GlobalStyles theme={theme} />
                {loading ? (
                  animation
                ) : (
                  <Suspense fallback={animation}>
                    <SessionProvider>
                      <Router>
                        <Root>
                          <AnimatedRoutes />
                        </Root>
                      </Router>
                    </SessionProvider>
                  </Suspense>
                )}
              </>
              <Toaster
                containerStyle={{ zIndex: 10000 }}
                position="bottom-center"
                toastOptions={{
                  style: {
                    borderRadius: 0,
                    padding: 0,
                    width: '100%',
                    background: 'transparent',
                    boxShadow: 'none',
                  },
                }}
              />
            </NextUIProvider>
          </ThemeProvider>
        </ApolloProvider>
      )}
    </>
  );
}

export default App;
